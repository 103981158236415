import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { component as Footer } from "@layout/footer"
import { component as Header } from "@layout/header"

import Layout from "@layout/no-header-footer"
import Grid from "@layout/grid"
import Card from "@components/card"
import Jumbotron from "@components/jumbotron"

import * as styles from "./styles.module.scss"

class ProductSectionPage extends React.Component {
  // data alias
  page = this.props.data.datoCmsProductSectionTruckVan

  getProductsSection() {
    return (
      <Grid
        items={`${this.props.data.productCategories.nodes.length}`}
        hasChildren
        gutter="15"
        layout="4"
      >
        {this.props.data.productCategories.nodes.map((category, index) => {
          const { title, section, thumbnail, permalink } = category

          const cardData = {
            body: `<a href="/${section.permalink}">${section.title}</a>`,
            heading: title,
            image: {
              childImageSharp: {
                gatsbyImageData: {
                  ...thumbnail.gatsbyImageData
                }
              }
            },
            theme: "media",
            url: `/${section.permalink}/${permalink}`
          }
          return <Card key={index} {...cardData} />
        })}
      </Grid>
    )
  }

  render() {
    const jumbotron = {
      backgroundImage: {
        childImageSharp: this.page.jumbotron.backgroundImage
      },
      body: this.page.jumbotron.body,
      heading: this.page.jumbotron.heading
    }

    return (
      <>
        <Helmet>
          <html lang="en" />
        </Helmet>

        <Header {...this.props.data.menu} />

        <div className={styles.productSectionJumbo}>
          <Jumbotron {...jumbotron}></Jumbotron>
        </div>

        <Layout seoMetaTags={this.page.seoMetaTags}>
          <div style={{ marginBottom: "70px", marginTop: "30px" }}>
            {this.props.data.productCategories.nodes && (
              <h2 style={{ marginBottom: "40px" }}>Categories</h2>
            )}
            {this.getProductsSection()}
          </div>
        </Layout>

        <Footer {...this.props.data.footer} />
      </>
    )
  }
}

export default ProductSectionPage

export const query = graphql`
  query ProductSectionTruckVanQuery($permalink: String!) {
    ...MegaMenu
    ...Footer

    productCategories: allDatoCmsProductCategoryTruckVan(
      filter: { productSection: { permalink: { eq: $permalink } } }
    ) {
      nodes {
        title
        permalink
        thumbnail {
          gatsbyImageData(aspectRatio: 1.5)
        }
        section: productSection {
          title
          permalink
        }
      }
    }
    datoCmsProductSectionTruckVan(permalink: { eq: $permalink }) {
      permalink
      title
      seoMetaTags {
        tags
      }
      jumbotron {
        backgroundImage {
          alt
          url
          title
          gatsbyImageData
        }
        buttons {
          label
          link
          theme
        }
        body
        heading
      }
    }
  }
`
